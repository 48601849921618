import React from "react"
import {Badge} from "react-bootstrap"
import {Link} from "gatsby"

import "../componentsStyles/bottoneBianco.css"

const BottoneBianco = props => (
  <Badge className={`${props.className} p-2`} pill variant="white">
    <Link
      to={props.link}
      classname={`badge badge-pill badge-primary ${props.variante}`}
    >
      {props.title}
    </Link>
  </Badge>
)

export default BottoneBianco