import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Sezione from "../components/sezione"
import SEO from "../components/seo"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import CardPagina from "../components/cardPagina"
import { Container, Row, Col } from "react-bootstrap"
import BottoneBianco from "../components/bottoneBianco"

const margin20 = {
  margin: "5px 5px",
}

const Azienda = () => {
  const images = useStaticQuery(graphql`
    query {
      fotovoltaico: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { regex: "partner/fotovoltaico/" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fixed {
                base64
                aspectRatio
                src
                srcSet
              }
            }
          }
        }
      }
      solare: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { regex: "partner/solare/" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fixed {
                base64
                aspectRatio
                src
                srcSet
              }
            }
          }
        }
      }
      mobilita: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { regex: "partner/mobilita/" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fixed {
                base64
                aspectRatio
                src
                srcSet
              }
            }
          }
        }
      }
      riqualificazione: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { regex: "partner/riqualificazione/" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fixed {
                base64
                aspectRatio
                src
                srcSet
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout isPagina={true}>
      <SEO
        title="Azienda specializzata in impianti ad energie rinnovabili a Roma, Aprilia, Ardea, Anzio, Nettuno"
        description="Azienda attiva nel settore degli impianti fotovoltaici e ad energie rinnovabili nel Lazio. La società è specializzata nella realizzazione di impianti fotovoltaici, eolici, solari termici e offre consulenza personalizzata"
        location="azienda-e-partner/"
      />
      <BanneronePage title="Azienda e Partner" />
      <Container className="mt-4 mb-4">
        <TitoloPagina title="Soluzioni integrate di Ingegneria Energetica Lazio: impianti fotovoltaici, solari termici, produzione di ACS per alberghi e agriturismi, termodinamici, consulenze energetiche, termografia a infrarossi" />
        <Row>
          <Col md={8}>
            <CardPagina variante="bordoLeft">
              <p>
                <strong>NonsoloEnergia srl</strong> forte in conoscenze ed
                innovazioni tecnologiche nel settore dell'Ingegneria
                Energetica, è un'azienda giovane, flessibile e dinamica,
                fortemente radicata sul territorio, che nasce nel 2006 allo
                scopo di rispondere alle esigenze delle aziende e dei privati,
                esigenze sempre più diversificate nei vari settori di competenza ed in
                particolar modo nella realizzazione di impianti ad energie
                rinnovabili e nell'efficientamento energetico.
              </p>
              <p>
                L'azienda, nel{" "}
                <u>
                  <em>Settore dell'Ingegneria Energetica</em>
                </u>
                , può contare sull'esperienza e sulla professionalità di un
                team di esperti ingegneri ambientali-elettrici e tecnici
                specializzati, su una consolidata ed innovativa capacità di
                consulenza nelle fasi di analisi di fattibilità, sviluppo,
                definizione, coordinamento e realizzazione di progetti di
                impianti fotovoltaici, termici per ACS e climatizzazione innovativa, termodinamici, costruzioni e
                ristrutturazioni di edifici ad elevato risparmio energetico,
                il tutto basato su un'ottima rete commerciale distribuita sul
                territorio del Lazio. Le principali macro aree del settore
                energetico sono costituite da: impianti fotovoltaici
                industriali, aziendali e residenziali, microgenerazione ad
                alto rendimento, impianti industriali per alberghi, agriturismi e per centri sportivi per la produzione di Acqua Calda Sanitaria (ACS), solare termodinamico, diagnosi e check-up
                energetico di edifici ed impianti con termografia ad
                infrarossi, energy manager, riqualificazione e certificazione
                energetica degli edifici aziendali e residenziali.
              </p>
              <p>
                Nel settore dei{" "}
                <u>
                  <em>Servizi di Ingegneria</em>
                </u>
                , l'azienda si avvale della competenza ed esperienza di
                ingegneri elettronici e delle telecomunicazioni per la
                fornitura di servizi ICT aziendali, monitoring
                elettromagnetico per la valutazione dei livelli di campo
                elettromagnetico e radiazioni non ionizzanti in tutta la
                regione Lazio e di ingegneri edili strutturisti per la
                progettazione e valutazione di strutture sedi di impianti di
                generazione fotovoltaica o termica. In questa direzione,{" "}
                <strong>NonsoloEnergia srl</strong> si propone di soddisfare,
                con soluzioni personalizzate, i fabbisogni delle imprese
                intervenendo presso la sede del cliente con i propri team di
                ingegneri, tecnici e commerciali, avvalendosi della
                partnership dei principali produttori mondiali di soluzioni
                energetiche di seguito riportati.
              </p>
            </CardPagina>
          </Col>
          <Col className="mx-auto mt-3" md={4}>
            <div className="text-center">
              <img
                className="mt-3"
                src="nonsoloenergia-azienda1.jpg"
                alt="Progettazione impianti Fotovoltaici Anzio"
                title="Progettazione Impianti Fotovoltaici Anzio"
              />
              <img
                className="mt-3"
                src="nonsoloenergia-azienda1bis.jpg"
                alt="Impianti Fotovoltaici Anzio"
                title="Realizzazione Impianti Fotovoltaici Anzio"
              />
            </div>

            <img
              className="mx-auto d-block mt-3"
              src="../nonsoloenergia-azienda2.jpg"
              alt="Impianti solari e fotovoltaici Lazio"
              title="Impianti solari, fotovoltaici, certificazione energetica Lazio"
            />
            <img
              className="mx-auto d-block mt-3"
              src="../nonsoloenergia-azienda3.jpg"
              alt="Efficientamento energetico Lazio"
              title="Efficientamento energetico Lazio, Roma, Anzio, Nettuno"
            />
            <div className="text-center">
              <img
                className="mt-3"
                src="../nonsoloenergia-azienda4.jpg"
                alt="Ricerca Infiltrazioni Muffa con termografia"
                title="Ricerca infiltrazioni, muffa, umidità, con termografia a infrarossi LAZIO"
              />
              <img
                className="mt-3"
                src="../nonsoloenergia-azienda4bis.jpg"
                alt="Impianti Fotovoltaici Anzio"
                title="Realizzazione impianti fotovoltaici anzio, nettuno, roma, latina, ardea, pomezia"
              />
            </div>
          </Col>
        </Row>
        <Sezione title="Principali Partner Commerciali">
          <CardPagina variante="bordoTop">
            <Row className="text-center">
              <Col>
                <h4 className="titolettoPagina">
                  <strong>
                    Fotovoltaico e sistemi di accumulo/batterie a litio ad
                    elevata efficienza:
                  </strong>
                </h4>
                {images.fotovoltaico.edges.map(image => (
                  <img
                    className="m-2"
                    src={image.node.childImageSharp.fixed.src}
                    alt={image.node.name.split("-").join(" ")}
                  />
                ))}
              </Col>
            </Row>
            <hr />
            <Row className="text-center mt-4">
              <Col>
                <h4 className="titolettoPagina">
                  <strong>
                    Solare termico, Termodinamico e microcogenerazione ad alto
                    rendimento:
                  </strong>
                </h4>
                {images.solare.edges.map(image => (
                  <img
                    className="m-2"
                    src={image.node.childImageSharp.fixed.src}
                    alt={image.node.name.split("-").join(" ")}
                  />
                ))}
              </Col>
            </Row>
            <hr />
            <Row className="text-center mt-4">
              <Col>
                <h4 className="titolettoPagina">
                  <strong>
                    Riqualificazione energetica, impianti di climatizazione ad
                    elevata efficienza e termografia ad infrarossi:
                  </strong>
                </h4>
                {images.riqualificazione.edges.map(image => (
                  <img
                    className="m-2"
                    src={image.node.childImageSharp.fixed.src}
                    alt={image.node.name.split("-").join(" ")}
                  />
                ))}
              </Col>
            </Row>
            <hr />
            <Row className="text-center mt-4">
              <Col>
                <h4 className="titolettoPagina">
                  <strong>Mobilità sostenibile:</strong>
                </h4>
                {images.mobilita.edges.map(image => (
                  <img
                    className="m-2"
                    src={image.node.childImageSharp.fixed.src}
                    alt={image.node.name.split("-").join(" ")}
                  />
                ))}
              </Col>
            </Row>
          </CardPagina>
          <CardPagina variante="white bordoTop mt-5">
        <Row>
          <Col xs={12} className="mt-3">
            <p className="titoloGrande text-center gridParity">
              Contattaci per ogni informazione inerente i nostri servizi. 
              Il nostro team di esperti sarà a tua disposizione per ogni necessità
            </p>
          </Col>
          <Col xs={12}>
            <BottoneBianco
              className="mt-3 mx-auto text-center variantePage"
              title="Contattaci ora"
              link="contatti"
            />
          </Col>
        </Row>
      </CardPagina>
        </Sezione>
      </Container>
    </Layout>
  )
}

export default Azienda
