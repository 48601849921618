import React from "react"

import "../componentsStyles/pagina.css"

const TitoloPagina = props => (
  <h1 className="titoloPagina">
    {props.title}
  </h1>
)

export default TitoloPagina
